import request from '@/libs/request'

var debug = false
var id = 1000;
var cache = [{
  id: 1,
  device: 'd123',
  vehicleNumber: '浙A19203',
  createTime: 1630930965
},{
  id: 2,
  device: 'd234',
  vehicleNumber: '浙A93912',
  createTime: 1630960965
}]

// 查询定位设备信息列表
export function listDevice(query) {
  if (debug) {
    let cached = cache.filter(c => (!query.device || c.device === query.device) && (!query.vehicleNumber || c.vehicleNumber === query.vehicleNumber))
    let start = Math.max((query.pageNum || 1) - 1, 0) * (query.pageSize || 10)
    let end = start + (query.pageSize || 10)
    return Promise.resolve({
      data:{
        count: cache.length, 
        list: cached.slice(start, end)
      }
    });
  }
  return request({
    url: '/shipping_device/list',
    method: 'post',
    data: query
  })
}