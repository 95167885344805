<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px" class="query-form">
      <el-form-item label="设备号" prop="device">
        <el-input
          v-model="queryParams.device"
          placeholder="请输入设备号"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="车牌号" prop="vehicleNumber">
        <el-input
          v-model="queryParams.vehicleNumber"
          placeholder="请输入车牌号"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="small" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="small" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <!-- <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          type="primary"
          plain
          icon="el-icon-plus"
          size="small"
          @click="handleAdd"
        >新增
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="success"
          plain
          icon="el-icon-edit"
          size="small"
          :disabled="single"
          @click="handleUpdate"
        >修改
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="danger"
          plain
          icon="el-icon-delete"
          size="small"
          :disabled="multiple"
          @click="handleDelete"
        >删除
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="warning"
          plain
          icon="el-icon-download"
          size="small"
          :loading="exportLoading"
          @click="handleExport"
        >导出
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="info"
          plain
          icon="el-icon-upload2"
          size="small"
          :loading="exportLoading"
          @click="open1=true"
        >导入
        </el-button>
      </el-col>
    </el-row> -->
    
    <el-table border stripe v-loading="loading" :data="deviceList" @selection-change="handleSelectionChange">
      <!-- <el-table-column type="selection" width="55" align="center"/> -->
      <el-table-column label="编号" fixed align="center" type="index"/>
      <!--      <el-table-column label="设备型号" align="center" prop="productModel"/>-->
      <el-table-column label="设备号" align="center" prop="device">
        <template slot-scope="scope">
          <!-- <el-link type="primary" :underline="false" @click="handleUpdate(scope.row)"> -->
            {{ scope.row.device }}
          <!-- </el-link> -->
        </template>
      </el-table-column>
      <el-table-column label="车牌号" align="center" prop="vehicleNumber"/>
      <el-table-column label="创建时间" align="center" prop="createTime">
        <template slot-scope="scope">{{ scope.row.createTime | formatDate }}</template>
      </el-table-column>
      <el-table-column label="操作" fixed width="200" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button size="small" type="text" icon="el-icon-connection" @click="bindFence(scope.row)">绑定围栏</el-button>
          <el-button size="small" type="text" icon="el-icon-view" @click="viewBoundFence(scope.row)">查看绑定线路</el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />

    <el-dialog title="绑定围栏" :visible.sync="bindDialogVisible" width="500px" append-to-body>
      <el-form ref="bindForm" :model="bindForm" :rules="bindRules" label-width="80px">
        <el-form-item label="设备号" prop="device">
          <el-input readonly v-model="bindForm.device" placeholder="请输入设备号"/>
        </el-form-item>
        <el-form-item label="起点围栏" prop="startFenceId">
          <el-select
            filterable
            remote
            clearable
            multiple
            :remote-method="(query) => queryFence(query, true)"
            :loading="fenceLoading"
            v-model="bindForm.startFenceId"
            placeholder="起点围栏，输入名称搜索"
            style="width:100%"
          >
            <el-option v-for="dict in startFenceList"
                       :key="dict.id"
                       :label="dict.fenceName"
                       :value="dict.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="终点围栏" prop="endFenceId">
          <el-select
            filterable
            remote
            clearable
            multiple
            :remote-method="(query) => queryFence(query, false)"
            :loading="fenceLoading"
            v-model="bindForm.endFenceId"
            placeholder="终点围栏，输入名称搜索"
            style="width:100%"
          >
            <el-option v-for="dict in endFenceList"
                       :key="dict.id"
                       :label="dict.fenceName"
                       :value="dict.id"
            ></el-option>
          </el-select>
        </el-form-item>
         <el-form-item label="是否上报sdk" prop="sdk">
            <el-radio-group v-model="bindForm.sdk">
              <el-radio :label="0">关闭</el-radio>
              <el-radio :label="1">开启</el-radio>
            </el-radio-group>

          </el-form-item>
          <el-form-item label="是否回调通知" prop="notify">
            <el-radio-group v-model="bindForm.notify">
              <el-radio :label="0">关闭</el-radio>
              <el-radio :label="1">开启</el-radio>
            </el-radio-group>

          </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitBindFence">确 定</el-button>
        <el-button @click="bindDialogVisible=false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listDevice,
} from "@/api/schedule/device";
import {listMapfence} from "@/api/schedule/mapfence";
import {addDevicefence} from "@/api/schedule/devicefence";
import Pagination from "./components/pagination.vue"

export default {
  name: "Device",
  dicts: ['device_status', 'device_type', 'power_status', 'locate_type', 'charger_state'],
  components: {Pagination},
  props: {
    device: String,
    vehicle: String
  },
  data() {
    return {
      // 遮罩层
      loading: true,
      showSearch: true,
      // 加载围栏
      fenceLoading: false,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 定位设备信息表格数据
      deviceList: [],
      // 是否显示弹出层
      bindDialogVisible: false,
      // 查询参数
      queryParams: {
        device: null,
        vehicleNumber: null,
        pageNum: 1,
        pageSize: 10,
      },
      bindForm: {
        device: '',
        startFenceId: [],
        endFenceId: [],
        sdk:1,
        notify:0
      },
      // 表单校验
      bindRules: {
        device: [{ required: true, message: "设备号不能为空", trigger: "blur" }],
        startFenceId: [{
          validator: (rule, value, callback) => {
            let endFenceId = this.bindForm.endFenceId
            if (endFenceId && endFenceId.length) {
              callback()
            } else {
              if (value && value.length) {
                callback()
              } else {
                callback(new Error())
              }
            }
          },
          message: '起点围栏/终点围栏至少填写一项',
          trigger: 'blur'
        }],
        endFenceId: [{
          validator: (rule, value, callback) => {
            let startFenceId = this.bindForm.startFenceId
            if (startFenceId && startFenceId.length) {
              callback()
            } else {
              if (value && value.length) {
                callback()
              } else {
                callback(new Error())
              }
            }
          },
          message: '起点围栏/终点围栏至少填写一项',
          trigger: 'blur'
        }],
      },
      startFenceList:[],
      endFenceList: []
    };
  },
  created() {
    if (this.device) {
      this.queryParams.device = this.device;
    }
    if (this.vehicle) {
      this.queryParams.vehicleNumber = this.vehicle;
    }
    this.getList();
  },
  methods: {
    /** 查询定位设备信息列表 */
    getList() {
      this.loading = true;
      let form = Object.fromEntries(
        Object.entries(this.queryParams).map(
          ([k, v]) => [k, v === '' ? null : v]
        )
      )
      listDevice(form).then(response => {
        this.deviceList = response.data.list;
        this.total = response.data.count;
      }).finally(()=> {
        this.loading = false;
      });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.$refs.queryForm.resetFields();
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    viewBoundFence(row) {
      this.$router.push({path: '/schedule/devicefence', query: {device: row.device}})
    },
    queryFence(query, isStart) {
      this.fenceLoading = true
      listMapfence({fenceName: query, pageNum: 1, pageSize: 99})
      .then(res=>{
        if (isStart) {
          this.startFenceList = res.data.list;
        } else {
          this.endFenceList = res.data.list;
        }
      }).finally(() => {
        this.fenceLoading = false;
      })
    },
    bindFence(row) {
      this.bindForm = {
        device: row.device,
        startFenceId: [],
        endFenceId: [],
        sdk:1,
        notify:0
      }
      this.bindDialogVisible = true;
    },
    submitBindFence() {
      this.$refs["bindForm"].validate(valid => {
        if (valid) {
          this.loading = true
          let form = {
            device: this.bindForm.device,
            startFenceId: this.bindForm.startFenceId.join(','),
            endFenceId: this.bindForm.endFenceId.join(','),
            sdk:this.bindForm.sdk,
            notify:this.bindForm.notify
          }
          addDevicefence(form)
          .then(response => {
            this.bindDialogVisible = false;
            this.$Message.success("新增成功");
          }).finally(() => {
            this.loading = false
          })
        }
      });
    },
  }
};
</script>
